<template>
  
  <div
    v-if="isVisible"
    :class="[isChildren ? 'col' : 'field', questionName, question.classnames, question.questionType]"
  >
    <label v-if="question.label" class="question-title">{{question.label}}</label>
    <div class="question-wrapper">
      <div :class="`grid-${question.gridSize || 1}`">

        <!-- Render error message -->
        <div
          v-if="error"
          class="col error"
          >
          <div
            class="error-message"
          >
            {{error}}
          </div>
        </div>

        <!-- Render question field following questionType -->
        <input-field v-if="question.questionType === 'input'" :question="question" :path="path" :responsePath="responsePath" />
        <text-field v-if="question.questionType === 'text'" :question="question" :path="path" :responsePath="responsePath" />
        <cleave-field v-if="question.questionType === 'cleave'" :question="question" :path="path" :responsePath="responsePath" />
        <radio-field v-else-if="question.questionType === 'radio'" :question="question" :path="path" :responsePath="responsePath" />
        <checkbox-field v-else-if="question.questionType === 'checkbox'" :question="question" :path="path" :responsePath="responsePath" />
        <select-field v-else-if="question.questionType === 'select'" :question="question" :path="path" :responsePath="responsePath" />
        <date-month-year-field v-else-if="question.questionType === 'date-month-year'" :question="question" :path="path" :responsePath="responsePath" />

        <!-- Render child questions if exists -->
        <question-layout
          v-for="(subQuestion, key) in subQuestions"
          :key="key"
          :questionName="key"
          :question="subQuestion"
          :path="`${path}.questions.${key}`"
          :responsePath="responsePath"
          :isChildren="true"
        />

        <!-- Render reset button if resetLabel exist -->
        <div
          class="col column"
          v-if="question.resetLabel && question.resetLabel.length >= 1"
        >
          <button
            type="button"
            :class="['btn btn-primary fullHeight', { selected: isResetSelected() }]"
            @click="resetQuestion({ path, responsePath })"
          >
            <span>{{question.resetLabel}}</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { find, get, pickBy, includes } from 'lodash';

  import { getError } from '../utils/validate';
  import { getProp } from '../utils/SurveyUtils';

  import InputField from './fields/InputField';
  import TextField from './fields/TextField';
  import CleaveField from './fields/CleaveField';
  import RadioField from './fields/RadioField';
  import CheckboxField from './fields/CheckboxField';
  import SelectField from './fields/SelectField';
  import DateMonthYearField from './fields/DateMonthYearField';

  export default {
    props: ['questionName', 'question', 'path', 'responsePath', 'isChildren'],
    computed: {
      ...mapGetters(['versionLight']),
      ...mapState({
        subQuestions(state) {
          let questions = this.question.questions;
          if (this.versionLight) {
            return pickBy(questions, obj => includes(obj.questionVersion, 'light'));
          }
          return pickBy(questions, obj => includes(obj.questionVersion, 'classic'));
        },

        isVisible(state) {
          return this.question.visibilityRules !== false &&
            (undefined === find(this.question.visibilityRules, (rules, dependency) =>
              getError(get(state, `survey.${dependency}`), rules) !== null));
        },
      }),
      error() {
        return getProp(this.question, this.responsePath, 'error');
      },
    },
    methods: {
      ...mapActions([
        'resetQuestion',
      ]),
      isResetSelected(question = this.question) {
        return getProp(question, this.responsePath, 'response') === null && find(question.questions, q => !this.isResetSelected(q)) === undefined;
      },
    },
    components: {
      InputField,
      TextField,
      CleaveField,
      RadioField,
      CheckboxField,
      SelectField,
      DateMonthYearField,
    },
  };
</script>
<style lang="scss">
</style>
