<template>
  <div
    v-for="(label, value) in getOptions(question.options)"
    :key="value"
    class="col"
  >
    <button
      type="button"
      :class="['btn btn-primary',question.fieldClassnames, { selected: value === response }]"
      @click="onClick(value)"
    >
      <i
        v-if="question.fieldClassnames && question.fieldClassnames.includes('icon')"
        :class="value"
      />
      <span>{{label}}</span>
    </button>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import { getOptions, getProp } from '../../utils/SurveyUtils';

  export default {
    props: ['question', 'path', 'responsePath'],
    computed: {
      response() {
        return getProp(this.question, this.responsePath, 'response');
      },
    },
    methods: {
      getOptions,
      ...mapActions([
        'setValue',
      ]),
      onClick(value) {
        return this.$store.dispatch('setValue', {
          path: this.path,
          responsePath: this.responsePath,
          value: value === this.response ? null : value,
        });
      },
    },
  };
</script>
