<template>
  <div class="subheader">
    <div class="container">
      <div class="subheader-title">
        {{title}}
      </div>
      <nav>
          <div
          v-for="(section, key) in sections"
          :key="key"
          :class="['step', { 'validated': section.isValidated }]">
          <router-link :to="{ name: 'Questionnaire', params: { section: key }, query: $route.query }" v-slot="{ isActive, navigate }" custom>
            <button @click="navigate" :disabled="isSurveyCompleted || $route.name === 'Confirmation'" :class="isActive ? 'router-link-active' : ''">
              <i></i>
              <span>{{sectionTitle(section)}}</span>
            </button>
          </router-link>
          <span class="line" />
          </div>
        <div
          v-if="!isSurveyCompleted"
          class="step confirmation"
        >
          <router-link :to="{ name: 'Confirmation', query: $route.query }" v-slot="{ isActive, navigate }" custom>
            <button @click="navigate" disabled :class="isActive ? 'router-link-active' : ''">
              <i></i>
              <span>Confirmation</span>
            </button>
          </router-link>
        </div>
        <div
          v-if="isSurveyCompleted"
          class="step completer"
        >
          <router-link :to="{ name: 'Complet', query: $route.query }" v-slot="{ isActive, navigate }" custom>
            <button @click="navigate" disabled :class="isActive ? 'router-link-active' : ''">
              <i></i>
              <span>Confirmation</span>
            </button>
          </router-link>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import { pickBy, includes, omitBy } from 'lodash';

  export default {
    computed: {
      ...mapGetters(['versionLight']),
      ...mapState({
        title: state => {
          let fullName = [];
  
          if (state.survey.sections.loisirs.questions.identite.questions.prenom.response) {
            fullName.push(state.survey.sections.loisirs.questions.identite.questions.prenom.response);
          }
          if (state.survey.sections.loisirs.questions.identite.questions.nom.response) {
            fullName.push(state.survey.sections.loisirs.questions.identite.questions.nom.response);
          }
  
          if (fullName && fullName.length > 0) {
            return `${fullName.join(' ')}, ${state.survey.title.charAt(0).toLowerCase() + state.survey.title.slice(1)}`;
          }
          return state.survey.title;
        },
        isSurveyCompleted: state => state.isSurveyCompleted,
        sections(state) {
          if (this.versionLight) {
            let sections = pickBy(state.survey.sections, obj => includes(obj.sectionVersion, 'light'));
            if (state.survey.sections.loisirs.questions.codeClient.response) {
              let s = omitBy(sections, (o, key) => key === 'loisirs');
              return s;
            }
            return pickBy(state.survey.sections, obj => includes(obj.sectionVersion, 'light'));
          } else {
            return pickBy(state.survey.sections, obj => includes(obj.sectionVersion, 'classic'));
          }
        },
      }),
    },
    methods: {
      sectionTitle(section) {
        if (this.versionLight && section.titleLight) {
          return section.titleLight;
        }
        return section.title;
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
