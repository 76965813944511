<template>
  <div class="col">
    <v-select
      :options="options"
      :placeholder="question.placeholder"
      :class="question.fieldClassnames"
      :modelValue="response"
      @option:selected="value => setValue({ path, responsePath, value })"
      selectOnTab
      taggable
      push-tags
    />
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import {map} from 'lodash';
  import VSelect from 'vue-select';

  import { getOptions, getProp } from '../../utils/SurveyUtils';

  export default {
    props: ['question', 'path', 'responsePath'],
    computed: {
      response() {
        return getProp(this.question, this.responsePath, 'response');
      },
    },
    data() {
      return {
        options: map(getOptions(this.question.options), label => label),
      };
    },
    methods: {
      ...mapActions([
        'setValue',
      ]),
    },
    components: {
      VSelect,
    },
  };
</script>
