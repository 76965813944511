import { filter, forEach, get } from 'lodash';

import optionsSports from '../../data/options-sports.json';
import optionsLoisirs from '../../data/options-loisirs.json';

const optionsList = {
  'options-sports': optionsSports,
  'options-loisirs': optionsLoisirs,
};

export const getOptions = options => typeof options === 'string'
  ? optionsList[options]
  : options;

export const getPath = (...paths) => filter(paths, path => path).join('.');

export const getProp = (question, ...paths) => get(question, getPath(...paths));

export const execQuestionDeep = (question = {}, path, responsePath, callback) => {
  const continueneedExecDeep = callback(question, path, responsePath);
  if (continueneedExecDeep !== false) {
    if (question.questions) {
      forEach(question.questions, (subQuestion, nameQuestion) => {
        return execQuestionDeep(
          subQuestion,
          `${path}.questions.${nameQuestion}`,
          responsePath,
          callback,
        );
      });
    }
    if (question.optionPrecisions && question.options) {
      forEach(question.optionPrecisions, (subQuestion, nameQuestion) => {
        forEach(question.options, (option, optionKey) => {
          return execQuestionDeep(
            subQuestion,
            `${path}.optionPrecisions.${nameQuestion}`,
            getPath(responsePath, `responses.${optionKey}`),
            callback,
          );
        });
      });
    }
  }
};

export const execSectionDeep = (section = {}, sectionName, callback) => {
  forEach(section.questions, (question, questionName) => {
    execQuestionDeep(question, `sections.${sectionName}.questions.${questionName}`, null, callback);
  });
};

export const execSurveyDeep = (survey = {}, callbackSection, callbackQuestion) => {
  forEach(survey.sections, (section, sectionName) => {
    callbackSection(section, sectionName);
    execSectionDeep(section, sectionName, callbackQuestion);
  });
};
