import { get, map } from 'lodash';

export const getResponseFromServer = {
  input: (serverData, serverPath) => get(serverData, serverPath),
  cleave: (serverData, serverPath) => get(serverData, serverPath),
  select: (serverData, serverPath) => get(serverData, serverPath),
  radio: (serverData, serverPath) => {
    const serverResponse = get(serverData, serverPath);
    if (serverResponse === true) {
      return 'oui';
    } else if (serverResponse === false) {
      return 'non';
    }
    return null;
  },
  checkbox: (serverData, serverPaths, resetServerPath) => {
    if (get(serverData, resetServerPath) === true) {
      return null;
    }
    const response = [];
    map(serverPaths, (serverPath, optionKey) => {
      if (get(serverData, serverPath) === true) {
        response.push(optionKey);
      }
    });

    return response;
  },
  'date-month-year': (serverData, serverPaths) => ({
    year: get(serverData, serverPaths.year),
    month: get(serverData, serverPaths.month),
  }),
};

export const getResponseFromSurvey = {
  input: (survey, path) => get(survey, path),
  cleave: (survey, path) => get(survey, path),
  select: (survey, path) => get(survey, path),
  radio: (survey, path) => {
    const surveyResponse = get(survey, path);
    if (surveyResponse === 'oui') {
      return true;
    } else if (surveyResponse === 'non') {
      return false;
    } else if (surveyResponse) {
      return surveyResponse;
    }
    return null;
  },
  checkbox: (survey, path, serverPaths, resetpath) => {
    const surveyResponse = get(survey, path);
    const values = {
      [resetpath]: surveyResponse === null ? true : null,
    };
    map(serverPaths, (serverPath, value) => {
      values[serverPath] = surveyResponse && surveyResponse.includes(value);
    });
    return values;
  },
  'date-month-year': (survey, path, serverPaths) => ({
    [serverPaths.year]: get(survey, `${path}.year`),
    [serverPaths.month]: get(survey, `${path}.month`),
  }),
};
