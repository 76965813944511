 <template>
  <div class="container">
    <question-layout
      v-for="(question, key) in questionsSection"
      :key="key"
      :questionName="key"
      :question="question"
      :path="`sections.${$route.params.section}.questions.${key}`"
    />
    <footer>
      <div class="grid-spaceBetween-noGutter">
        <div class="col">
          <router-link
            v-if="previousSection"
            :to="{ name: 'Questionnaire', params: { section: previousSection }, query: $route.query }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small">
              <i class="arrow left"/>
              <span>PRÉCÉDENT</span>
            </button>
          </router-link>
        </div>
        <div class="col-right">
          <router-link
            v-if="nextSection"
            :to="{ name: 'Questionnaire', params: { section: nextSection }, query: $route.query }" 
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small">
              <i class="arrow right"/>
              <span>SUIVANT</span>
            </button>
          </router-link>
          <router-link
            v-if="!nextSection"
            :to="{ name: 'Confirmation', query: $route.query }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small">
              <span>VALIDER</span>
            </button>
          </router-link>
        </div>
      </div>
      <div class="cgu" onload="subst()">
        Les informations recueillies ont pour objectif de mieux vous connaître
        afin de vous conseiller et vous proposer les offres les plus adaptées à
        votre situation et vos besoins. Elles sont destinées à nos services
        internes et nos prestataires. Les réponses aux questions posées sont
        facultatives. Vous pouvez exercer vos droits sur vos données (droit
        d’accès, de rectification, de suppression, d’opposition, de limitation)
        en vous adressant par courrier postal au Délégué à la Protection des
        Données de Groupama Loire Bretagne, 23, Boulevard Solférino CS 51209
        35012 Rennes cedex.
        <br><br>
        En remplissant ce questionnaire portant sur vos projets et vos
        changements de vie, vous autorisez Groupama, à vous contacter par email
        ou par téléphone, pour vous proposer de l'information, des conseils, des
        services et des offres adaptés à vos besoins, en lien avec la mise en
        œuvre de votre projet. Les réponses aux questions sont facultatives et
        les informations recueillies ne seront en aucun cas transmises à des
        sociétés extérieures.
      </div>

    </footer>

  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { keys, pickBy, includes } from 'lodash';
  import store from '../store';

  export default {
    computed: {
      ...mapGetters(['versionLight']),
      ...mapState({
        versionLight(state) {
          return this.$route.query && (this.$route.query.version && this.$route.query.version.toLowerCase() === 'light');
        },
        questionsSection(state) {
          let questions = state.survey.sections[this.$route.params.section].questions;
          if (this.versionLight) {
            return pickBy(questions, obj => includes(obj.questionVersion, 'light'));
          }
          return pickBy(questions, obj => includes(obj.questionVersion, 'classic'));
        },
        section(state) {
          return state.survey.sections[this.$route.params.section];
        },
        previousSection(state) {
          const sections = keys(state.survey.sections);
          if (this.versionLight && this.$route.params.section === 'projets' && state.survey.sections.loisirs.questions.codeClient.response) {
            return null;
          }
          if (this.versionLight && this.$route.params.section === 'projets') {
            return sections[sections.indexOf(this.$route.params.section) - 2];
          }
          return sections[sections.indexOf(this.$route.params.section) - 1];
        },
        nextSection(state) {
          const sections = keys(state.survey.sections);
          if (this.versionLight && this.$route.params.section === 'loisirs') {
            return sections[sections.indexOf(this.$route.params.section) + 2];
          }
          return sections[sections.indexOf(this.$route.params.section) + 1];
        },
      }),
    },
    methods: {
      ...mapActions([
        'validateSection',
        'sendSurvey',
      ]),
      onRouteChange(to, from) {
        this.validateSection(from.params.section)
        .then((props) => {
          if (this.section.isValidated === true) {
            this.sendSurvey({ survey: this.$store.state.survey });
          }
        });
        window.scroll(0, 0);
        return this.section.isValidated;
      },
    },
    beforeRouteEnter(to, from, next) {
      if (!store.state.isSurveyCompleted) {
        next();
      }
    },
    beforeRouteUpdate(to, from) {
      return this.onRouteChange(to, from);
    },
    beforeRouteLeave(to, from) {
      return this.onRouteChange(to, from);
    },
  };
</script>
<style lang="scss" scoped>
</style>
