import axios from 'axios';
import { get, forEach, keys, split } from 'lodash';
import { set as setFP } from 'lodash/fp';
import { getPath, getProp, execSurveyDeep } from '../utils/SurveyUtils';
import { getResponseFromSurvey } from '../utils/ServerParser';

export const CHECK_ERROR = 'CHECK_ERROR';
export const SET_VALUE = 'SET_VALUE';
export const RESET_QUESTION = 'RESET_QUESTION';
export const VALIDATE_SECTION = 'VALIDATE_SECTION';
export const FETCH_SURVEY = 'FETCH_SURVEY';
export const SEND_SURVEY = 'SEND_SURVEY';
export const SET_ID = 'SET_ID';

const actions = {
  checkError({ commit }, data) {
    commit(CHECK_ERROR, data);
  },
  setValue({ commit }, data) {
    commit(SET_VALUE, data);
  },
  resetQuestion({ commit }, data) {
    commit(RESET_QUESTION, data);
  },
  validateSection({ commit }, data) {
    commit(VALIDATE_SECTION, data);
  },
  fetchSurvey({ commit }, data) {
    commit(FETCH_SURVEY, data);
  },
  sendSurvey({ commit }, data) {
    let survey = data.survey;
    let responses = {};
    let arrayResponse = [];
    execSurveyDeep(
      survey,
      (s, sn) => {
        if (s.serverPathValidated && get(survey, `sections.${sn}.isValidated`) === true) {
          responses = setFP(s.serverPathValidated, true, responses);
        }
      },
      (q, p, rp, isArray) => {
        const serverPath = getProp(q, 'serverPath', rp);
        if (serverPath && q.questionType && getResponseFromSurvey[q.questionType]) {
          const values = getResponseFromSurvey[q.questionType](
            survey,
            getPath(p, rp, 'response'),
            serverPath,
            q.resetServerPath,
            split(p, '.').pop(),
            p
          );
          if (isArray) {
            arrayResponse =
              get(responses, serverPath) !== undefined
                ? [...get(responses, serverPath), values]
                : [values];
            responses = setFP(serverPath, arrayResponse, responses);
          } else if (typeof values === 'object' && keys(values).length > 0) {
            forEach(values, (value, path) => {
              responses = setFP(path, value, responses);
            });
          } else {
            if (get(responses, serverPath) === undefined) {
              responses = setFP(serverPath, values, responses);
            }
          }
        }
      }
    );
    axios
      .post(`${process.env.API_URL}/FormLoisirs/setClient`, responses)
      .then(({ data }) => {
        commit(SET_ID, data);
      })
      .catch(e => console.log(e));
  },
};

export default actions;
