import { createApp } from 'vue';

import './assets/styles/app.scss';

import VueSelect from 'vue-select';
import DOMPurify from 'dompurify';

import router from './router';
import MainLayout from './components/layouts/MainLayout';
import store from './store';

import { getJSONFromUrlParams } from './utils/URLParser';

// Import global componenents
import QuestionLayout from './components/QuestionLayout';

/* eslint-disable no-new */
const app = createApp({
  setup() {
    return {};
  },

  template: '<main-layout/>',
  components: {
    MainLayout,
  },
  created() {
    const codeClient = getJSONFromUrlParams().codeClient;
    if (codeClient) {
      store.dispatch('fetchSurvey', codeClient);
    }
  },
  watch: {
    '$store.state.isSurveyCompleted': function(isSurveyCompleted) {
      if (isSurveyCompleted === true) {
        router.push({ name: 'Complet' });
      }
    },
    '$store.state.survey.sections.loisirs.questions.codeClient.response': function(response) {
      if (response) {
        let q = router.currentRoute.query;
        let section = router.currentRoute.params.section;
        let versionlight = q && (q.version && q.version.toLowerCase() === 'light');
        if (versionlight && section === 'loisirs') {
          router.push({ name: 'Questionnaire', params: { section: 'projets' }, query: q });
        }
      }
    },
  },
});

app.use(router);
app.use(store);
// Initiate other plugins here

// QuestionLayout must be global to be call by their children...
// (Why can't use module import in the children, don't know....)
app.component('question-layout', QuestionLayout);
app.component('v-select', VueSelect);

app.directive('sane-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

app.mount('#root');

if (__DEV__) {
  // Remove productionTip
  app.config.productionTip = false;
}

export default app;
