 <template>
  <div class="container text-center">
    <div class="confirmation-block">
      <i class="icon-confirmation check" />
      <div v-if="versionLight" class="confirm-message">
        Merci de vos réponses. Nous restons à votre écoute pour vous accompagner dans tous vos projets ou moments de vie.      
      </div>
      <div v-else class="confirm-message">
        Merci de nous avoir partagé récemment vos projets. 
      </div>
      <div class="confirm-message">
        Nous mettons à votre disposition une ligne dédiée et prioritaire « Vos projets : nos conseils », n’hésitez pas à nous contacter au : <span class="tel">02 96 58 60 50</span> <span class="annot">(Coût d'un appel local)</span>
      </div>
      <div class="confirm-question">
        Vous souhaitez être contacté(e) pour faire le point sur vos assurances ?
      </div>
    </div>
    <footer class="confirmation-footer">
      <div class="grid-center-noBottom">
        <a class="btn btn-secondary btn-big" target="_blank" href="https://espaceclient.groupama.fr/front/rdv">
          <i class="icon calendar" />
          <span>Je prends rendez-vous</span>
          <i class="arrow right" />
        </a>
      </div>
      <div class="cgu">
      </div>

    </footer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['versionLight']),
  },
};
</script>
<style lang="scss" scoped>
</style>
