<template>
  <div :class="`${question.answerColClass || 'col'}`">
    <div v-if="question.bloc" :class="question.classBloc" v-sane-html="question.bloc"></div>
  </div>
</template>
<script>
export default {
  props: ['question', 'path', 'responsePath'],
  computed: {},
};
</script>