<template>
  <footer class="app-footer">
  </footer>
</template>
<script>
  export default {
  };
</script>
<style lang="scss" scoped>
</style>
