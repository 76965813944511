import { find, includes } from 'lodash';
import isEmail from 'validator/lib/isEmail';

// Return boolean, true if the rule is completed
export const validate = {
  required: value => value !== undefined && value !== null && (!Number.isNaN(value) || value.length > 0),
  empty: value => !validate.required(value),
  email: value => isEmail(value),
  alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -]+$/i.test(value),
  special_chars_alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -/]+$/i.test(value),
  numeric: value => /^[0-9 ]+$/i.test(value),
  regexp: (value, parameters) => new RegExp(parameters.model).test(value),
  equals: (value, parameters) => value === parameters,
  maxLength: (value, parameters) => value.length <= parameters.length,
  minLength: (value, parameters) => value.length >= parameters.length,
  maxLengthIfExists: (value, parameters) => validate.empty(value) || value.length <= parameters.length,
  minLengthIfExists: (value, parameters) => validate.empty(value) || value.length >= parameters.length,
  length: (value, parameters) => {
    if (!value) {
      return true;
    }
    return (Array.isArray(parameters.length)) ? find(parameters.length, (params) => { return (params === 0) ? validate.empty(value) : value !== undefined && value !== null && value.length === params; }) : value.length === parameters.length;
  },
  contains: (value, parameters) => includes(value, parameters),
  greater: (value, parameters) => value > parameters,
  greaterEquals: (value, parameters) => value >= parameters,
  greaterIfExists: (value, parameters) => validate.empty(value) || value > parameters,
  greaterEqualsIfExists: (value, parameters) => validate.empty(value) || value >= parameters,
  lower: (value, parameters) => value < parameters,
  lowerEquals: (value, parameters) => value <= parameters,
  lowerIfExists: (value, parameters) => validate.empty(value) || value < parameters,
  lowerEqualsIfExists: (value, parameters) => validate.empty(value) || value <= parameters,
};

export function getError(value, rules) {
  const ruleError = find(rules, (params, rule) => !validate[rule](value, params));
  return ruleError
    ? ruleError.message || ruleError
    : null;
}
