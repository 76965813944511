 <template>
  <div class="container text-center">
    <div class="confirmation-block">
      <i class="icon-confirmation check" />
      <div class="confirm-message">
       Vous avez déjà répondu au questionnaire et nous vous en remercions.<br/>
       Vos réponses ainsi que votre participation au jeu<sup>*</sup> ont bien été prises en compte.
      </div>
      <p>
      </p>
      <div class="confirm-question">
        Vous souhaitez être contacté(e) pour faire le point sur vos assurances ?
      </div>
    </div>
    <footer class="confirmation-footer">
      <div class="grid-center-noBottom">
        <a class="btn btn-secondary btn-big" target="_blank" href="https://www.groupama.fr/prendre-rdv-avec-un-conseiller-particuliers.html?cr=lbr&xtor=Vform&lm=Vform-Rela-C-QJeuLoisirs-4LBR">
          <i class="icon calendar" />
          <span>Je prends rendez-vous</span>
          <i class="arrow right" />
        </a>
      </div>
      <div class="cgu">
        <br>Les informations recueillies ont pour objectif de mieux vous connaître afin de vous conseiller et vous proposer les offres les plus adaptées à votre situation et vos besoins. Elles sont destinées à nos services internes et nos prestataires. Les réponses aux questions posées sont facultatives.
        Vous pouvez exercer vos droits sur vos données (droit d’accès, de rectification, de suppression, d’opposition, de limitation) en vous adressant par courrier postal au Délégué à la Protection des Données
        de Groupama Loire Bretagne, 23, Boulevard Solférino CS 51209 35012 Rennes cedex.
        <br><br>
        En remplissant ce questionnaire portant sur vos projets et vos
        changements de vie, vous autorisez Groupama, à vous contacter par email
        ou par téléphone, pour vous proposer de l'information, des conseils, des
        services et des offres adaptés à vos besoins, en lien avec la mise en
        œuvre de votre projet. Les réponses aux questions sont facultatives et
        les informations recueillies ne seront en aucun cas transmises à des
        sociétés extérieures.
      </div>
      <div class="cgu">
        <sup>*</sup>Jeu gratuit sans obligation d’achat organisé par Groupama Loire Bretagne du 01/03/2019 au 31/12/2019. Dotation mise en jeu : 20 chèques Cadhoc d’une valeur unitaire de 50 €,
        soit une dotation totale de 1 000 €. Pour participer, il suffit de compléter le questionnaire accessible via l’email reçu. Le règlement des opérations sera adressé à titre gratuit
        à toute personne qui en fait la demande par courrier à Groupama Loire Bretagne - Service Marketing - 23 boulevard Solférino - CS 51209 - 35012 Rennes cedex.
      </div>
    </footer>
  </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
</style>