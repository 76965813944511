<template>
  <div class="col">
    <input
      type="text"
      :placeholder="question.placeholder"
      :class="[filled, question.fieldClassnames]"
      :value="response"
      @change="e => setValue({ path, responsePath, value: e.target.value })"
      @input="e => checkError({ path, responsePath, value: e.target.value })"
    />
    <label v-if="question.labelInput">{{question.labelInput}}</label>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  import { getProp } from '../../utils/SurveyUtils';

  export default {
    props: ['question', 'path', 'responsePath'],
    computed: {
      response() {
        return getProp(this.question, this.responsePath, 'response');
      },
      filled() {
        return this.response && this.response.length > 0 && 'filled';
      },
    },
    methods: {
      ...mapActions([
        'setValue',
        'checkError',
      ]),
    },
  };
</script>
