import axios from 'axios';
import { get, set, find } from 'lodash';
import { set as setFP } from 'lodash/fp';

import {
  CHECK_ERROR,
  SET_VALUE,
  RESET_QUESTION,
  VALIDATE_SECTION,
  FETCH_SURVEY,
  SET_ID,
} from './actions';

import { getError } from '../utils/validate';
import {
  getPath,
  getProp,
  execQuestionDeep,
  execSectionDeep,
  execSurveyDeep,
} from '../utils/SurveyUtils';
import { getResponseFromServer } from '../utils/ServerParser';

const mutations = {
  [CHECK_ERROR]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    // Use set (not setFP) to not re-create the question with the new error props (don't display the new error on user input)
    set(state.survey, getPath(path, responsePath, 'error'), getError(value, question.rules));
  },
  [SET_VALUE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    survey = setFP(getPath(path, responsePath, 'response'), value, survey);
    survey = setFP(getPath(path, responsePath, 'error'), getError(value, question.rules), survey);
    state.survey = survey;
  },
  [RESET_QUESTION]: (state, { path, responsePath }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    execQuestionDeep(question, path, responsePath, (q, p, rp) => {
      survey = setFP(getPath(p, rp, 'response'), null, survey);
      survey = setFP(getPath(p, rp, 'error'), getError(null, q.rules), survey);
    });
    state.survey = survey;
  },
  [VALIDATE_SECTION]: (state, sectionName) => {
    let survey = state.survey;
    let sectionHasError = false;
    execSectionDeep(get(state, `survey.sections.${sectionName}`), sectionName, (q, p, rp) => {
      const isVisible =
        q.visibilityRules !== false &&
        undefined ===
          find(
            q.visibilityRules,
            (rules, dependency) => getError(get(survey, dependency), rules) !== null
          );
      if (isVisible) {
        const error = getError(getProp(q, rp, 'response'), q.rules);
        sectionHasError = sectionHasError || error;
        survey = setFP(getPath(p, rp, 'error'), error, survey);
        return true;
      }
      return false;
    });
    survey = setFP(`sections.${sectionName}.isValidated`, !sectionHasError, survey);
    state.survey = survey;
  },
  [FETCH_SURVEY]: (state, codeClient) => {
    state.surveyWithClientCode = true;
    axios
      .get(`${process.env.API_URL}/FormLoisirs/getClient?codeClient=${codeClient}`)
      .then(({ data }) => {
        let allSectionsValidated = true;
        let survey = state.survey;
        execSurveyDeep(
          survey,
          (s, sn) => {
            if (get(data, s.serverPathValidated) === true) {
              survey = setFP(`sections.${sn}.isValidated`, true, survey);
            } else {
              allSectionsValidated = false;
            }
          },
          (q, p, rp) => {
            const serverPath = getProp(q, 'serverPath', rp);
            if (serverPath) {
              survey = setFP(
                getPath(p, rp, 'response'),
                getResponseFromServer[q.questionType](data, serverPath, q.resetServerPath),
                survey
              );
            }
          }
        );
        state.survey = survey;
        if (allSectionsValidated) {
          state.isSurveyCompleted = true;
        }
      })
      .catch(e => console.log(e));
  },
  [SET_ID]: (state, data) => {
    state.survey = setFP('sections.loisirs.questions.exportId.response', data.id, state.survey);
  },
};

export default mutations;
