<template>
  <div class="layout">
    <app-header v-if="showAppHeaderFooter" />
    <v-header />
    <div class="vue-views">

      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <keep-alive v-if="$route.meta && $route.meta.keepAlive !== false">
            <component :is="Component" />
          </keep-alive>
          <component :is="Component" v-else />
        </Transition>
      </router-view>

    </div>
    <app-footer v-if="showAppHeaderFooter" />
  </div>
</template>
<script>
  import { mapState } from 'vuex';

  import VHeader from './Header';
  import AppHeader from './AppHeader';
  import AppFooter from './AppFooter';

  export default {
    computed: mapState({
      showAppHeaderFooter: state => state.surveyWithClientCode,
    }),
    components: {
      VHeader,
      AppHeader,
      AppFooter,
    },
  };
</script>
